import { createApp } from "vue-demi";
import gsap from "gsap";
import { wrapperAsyncComponent } from "../Loading";
const EMAIL_HOST_SYMBOL = "EMAIL_HOST_SYMBOL";

/**
 * 需求：
 * 1. 优化弹窗展示的动画
 * 2. 优化弹窗弹出的逻辑
 * 2.1 如果用户没有任何操作，在10s后弹出弹窗
 * 2.2 如果用户已经阅读过弹窗，本次会话将不再自动触发弹窗
 * 通过gsap动画来实现一个全屏弹窗
 * 使用单例模式来控制
 * @param el
 * @param param1
 * @returns
 * 1. 引入vue组件，创建dom节点
 * 2. 执行过渡动画
 */
export const useEmailModalInstance = ({ onOpen, onSuccess, onFail }: { onOpen?: () => void; onSuccess?: () => void; onFail?: () => void } = {}) => {
    //如果是服务端则不渲染
    const { brand } = useHost();
    if (process.server)
        return {
            open: () => void 0,
            close: () => void 0,
            sessionControl: null,
        };

    const sessionControl = createSessionControl("email-modal");

    const app = useNuxtApp();
    let hostEl: HTMLDivElement | null;
    /**
     * @type { -1 | 0 | 1} -1 没有准备好， 0 准备完之后需要打开弹窗 1 已经准备好了
     */
    let isReady: -1 | 0 | 1 = -1;

    function initHostElement() {
        hostEl = document.querySelector(`#${EMAIL_HOST_SYMBOL}`);

        if (hostEl) {
            onLoaded();
            return;
        }

        if (!hostEl) {
            hostEl = document.createElement("div");
            hostEl.id = EMAIL_HOST_SYMBOL;
            /**@refer https://vuejs.org/guide/components/async.html */
            const childComp = wrapperAsyncComponent(() => import(brand === "ergo" ? "../../ergo/CouponEmail/index.vue" : "../../Card/EmailDialog/index.vue"), { onClose: handleClose, onLoaded, onSuccess, onFail });
            const modal = createApp(childComp);

            /**@link https://github.com/vuejs/core/issues/2097#issuecomment-1359372176 */
            Object.assign(modal._context, app.vueApp._context);
            modal.mount(hostEl);
            document.body.append(hostEl);
        }
        // gsap.set(hostEl, { position: "fixed", display: "none", opacity: 0, y: 0, x: 0, scale: 0, top: 0, zIndex: 3000, width: "100%", height: "100%" });
        gsap.set(hostEl, { position: "fixed", background: "rgba(0, 0, 0, 0.5)", autoAlpha: 0, y: 0, x: 0, top: 0, left: 0, right: 0, bottom: 0, zIndex: 3000, width: "100vw", height: "100vh" });
    }

    function onLoaded() {
        if (isReady === 0) {
            handleOpen();
        }
        isReady = 1;
    }

    onMounted(() => {
        initHostElement();
    });

    let prevStatus = "";

    function handleClose() {
        // gsap.to(hostEl, { display: "none", opacity: 0, y: 0, duration: 0.3, scale: 0 });
        gsap.to(hostEl, { autoAlpha: 0, y: 0, duration: 0.3 });
        // sessionControl.closeOpen();
        document.body.style.overflow = prevStatus;
    }

    async function handleOpen() {
        if (isReady !== 1) {
            isReady = 0;
            return;
        }
        if (checkVideoPlaying()) return null;
        // gsap.to(hostEl, { display: "block", opacity: 1, y: 0, duration: 0.3, scale: 1 });
        gsap.to(hostEl, { autoAlpha: 1, y: 0, duration: 0.3 });
        onOpen && onOpen();
        sessionControl.setOpen();
        prevStatus = document.body.style.overflow;
        document.body.style.overflow = "hidden";
    }

    return {
        open: handleOpen,
        close: handleClose,
        sessionControl,
    };
};

function createSessionControl(keyStr: string) {
    const result = {
        isActioned: sessionStorage.getItem(keyStr) === "true",
        setOpen() {
            if (result.isActioned) return;
            sessionStorage.setItem(keyStr, "true");
            result.isActioned = true;
        },
        closeOpen() {
            sessionStorage.setItem(keyStr, "false");
        },
    };

    return result;
}
