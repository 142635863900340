/**如果当前页面中正在有视频播放，并且视频不处于mute状态，则不需要进行弹窗展示 */
export function checkVideoPlaying() {
    // 检查页面中的 <video> 元素
    const videos = document.querySelectorAll("video");
    for (let i = 0; i < videos.length; i++) {
        const video = videos[i] as HTMLVideoElement;
        if (!video.muted && !video.paused) {
            return true;
        }
    }

    // 检查嵌套在 <iframe> 中的 YouTube 视频
    const isInYoutube = checkYouTubeIframe();

    return isInYoutube || false;
}

/**针对YouTube视频(无法直接判断用户是否正在看)，但是一般在弹窗中播放，所以判断是否已经打开youtube播放弹窗
 * 如果当前存在已经打开弹窗的视频（videoPopup.style.display === 'none'），则不需要提示email */
function checkYouTubeIframe() {
    const videoPopup = [...document.querySelectorAll(".video-popup")] as HTMLDivElement[];
    const hasVideo = videoPopup.some((item) => {
        if (item.style.display !== "none") {
            return true;
        }
    });
    return hasVideo;
}

// if (import.meta.client) {
//     window.checkVideoPlaying = checkVideoPlaying;
// }

// function checkYouTubeIframe(iframe: HTMLIFrameElement): Promise<boolean> {
//     return new Promise((resolve) => {
//         const player = new YT.Player(iframe, {
//             events: {
//                 onReady: (event) => {
//                     const playerState = event.target.getPlayerState();
//                     const isMuted = event.target.isMuted();
//                     resolve(playerState === YT.PlayerState.PLAYING && !isMuted);
//                 },
//                 onStateChange: (event) => {
//                     if (event.data === YT.PlayerState.PLAYING && !event.target.isMuted()) {
//                         resolve(true);
//                     } else {
//                         resolve(false);
//                     }
//                 },
//             },
//         });
//     });
// }

// // 加载 YouTube IFrame Player API
// function loadYouTubeAPI() {
//     return new Promise((resolve) => {
//         if (window.YT) {
//             resolve(window.YT);
//         } else {
//             const tag = document.createElement("script");
//             tag.src = "https://www.youtube.com/iframe_api";
//             tag.onload = () => {
//                 window.onYouTubeIframeAPIReady = () => {
//                     resolve(window.YT);
//                 };
//             };
//             document.head.appendChild(tag);
//         }
//     });
// }

// // 在页面加载时初始化 YouTube API
// loadYouTubeAPI();
