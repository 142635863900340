import { defineComponent, defineAsyncComponent } from "vue";
import LoadingWrapper from "./Wrapper.vue";
export const wrapperAsyncComponent = (asyncCompoent: any, props: any = {}) => {
    const Async = defineAsyncComponent(asyncCompoent);
    return defineComponent({
        render() {
            return (
                <LoadingWrapper>
                    <Async {...props}></Async>
                </LoadingWrapper>
            );
        },
    });
};
