<template>
    <div class="suspense-component">
        <Suspense>
            <template #default>
                <slot></slot>
            </template>
            <template #fallback>
                <span> ...loading</span>
            </template>
        </Suspense>
    </div>
</template>
